import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ProjectListItem from './list-item'

class ProjectListing extends Component {
    render() {
        if (this.props.data) {
            return (
                <div className="c-project-listing">
                    <div className="container">
                        <div className="row">
                            {this.props.data.map((article, i) => {
                                return (
                                    <ProjectListItem key={i} article={article} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        }
        
        return null
    }
}

ProjectListing.propTypes = {
    data: PropTypes.array,
    basePath: PropTypes.string
}

export default ProjectListing;